<template>
  <CRow class="mt-2">
    <loading-overlay  :active="loadingOverlay"  :is-full-page="true"  loader="bars"/>
    <CCol sm="12">
      <CRow class="mb-3">
        <CCol sm="8" class="d-flex align-items-center">
          <CButton
              color="edit"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.return'),
                placement: 'top',
              }"
              size="sm"
              @click="closeCollapse"
            >
              <CIcon name="cil-chevron-left-alt" />
            </CButton>
            <h6 class="mt-2"> {{ $t('label.timeLine')+' - '+$t('label.noOrder')+': '+orderNumber }}</h6>
        </CCol>  
        <CCol sm="4" class="d-flex align-items-center justify-content-end ">
          
        </CCol>
        <CCol sm="12" class="mt-3">
          <CRow class="d-flex justify-content-center">
            <div class="table-responsive col-sm-11">
              <table class="table Time-Line-table">
                <thead>
                  <tr >
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.D_emission')}}</div>
                    </th>
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.D_shipping')}}</div>
                    </th>
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.vehicle')}}</div>
                    </th>
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.Gamela')}}</div>
                    </th>
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.crane')}}</div>
                    </th>
                    <th class="bg-watch">
                      <div class="text-center ">{{$t('label.hold')}}</div>
                    </th>
                    <th class="bg-edit">
                      <div class="text-center ">{{$t('label.totalTime')}}</div>
                    </th>
                    <th class="bg-edit">
                      <div class="text-center ">{{$t('label.lostTime')}}</div>
                    </th>
                    <th class="bg-edit">
                      <div class="text-center ">{{$t('label.effectiveTime')}}</div>
                    </th>
                  </tr>
                </thead>    
                <tbody>
                  <tr>
                    <td class="bg-watch text-center" style="width: 11,11%;">{{ circuitData.TransactionDate}}</td>
                    <td class="bg-watch text-center" style="width: 11,11%;">{{ circuitData.FinishDate}}</td>                                                       
                    <td class="bg-watch text-center" style="width: 11,11%;">{{ circuitData.LicensePlate}}</td>
                    <td class="bg-watch text-center" style="width: 11,11%;">{{ circuitData.ImplementAlias}}</td>
                    <td class="bg-watch text-center" style="width: 11,11%;">{{ circuitData.CraneName}}</td>                                                       
                    <td class="bg-watch text-center" style="width: 11,11%;">{{circuitData.VesselHoldName}}</td>
                    <td class="bg-edit text-center" style="width: 11,11%;">{{ TotalTimeHrsDs }}</td>
                    <td class="bg-edit text-center" style="width: 11,11%;">{{ DelayHrsDs }}</td>                                                       
                    <td class="bg-edit text-center" style="width: 11,11%;">{{ EffectiveHrsDs }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CRow>
        </CCol>
        <div class="col-md-12">
          <div ref="imagesave" >
            <div class="main-timeline-vertical">
              <section id="cd-timeline" class="cd-container">
                <div class="cd-timeline-block" v-for="(item, index) in ListTimeLine" :key="index">
                  <div class="cd-timeline-img cd-movie">
                  </div>
                  <div class="cd-timeline-content py-2">
                    <h2>{{item.date}}</h2>
                    <h2>{{item.dateBegin}}</h2>
                    <h2>{{item.dateFinish }}</h2>
                    <h4>{{titleTimeLineEvent(item)}}</h4>
                    <h2 v-if="item.IncidenceId != ''">
                      <CIcon name='alertas' color="wipe" size='lg' class=" mb-2 mr-1"/>&nbsp;
                      {{titleIncidence(item)}}
                    </h2>
                    <h2>{{titleWeigth(item)}}</h2>
                    <span class="cd-date">{{ item.TotalTime }}</span>
                  </div> <!-- cd-timeline-content -->
                </div>
              </section>
            </div>
          </div>
        </div>
      </CRow>
      
    </CCol>
  </CRow>
</template>
<script>
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import DataStorage from '@/_helpers/localStorage';
import ENUM from '@/_store/enum';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function data() {
  return {
    loadingOverlay: false,
    refrescarTabla: false,
    VisitId: '',
    orderNumber: '',
    order: '',
    DelayHrsDs: '',
    EffectiveHrsDs: '',
    TotalTimeHrsDs: '',
    timeLineData:[],
  };
}

function closeCollapse(){
  this.$emit(' closeCollapseTimeLine', 0);
  this.$emit('child-refresh', true);
}

function titleTimeLineEvent(item){
  let local = DataStorage.getLocale();
  return local == 'es'? item.LabelTimeLineEvent.Es : item.LabelTimeLineEvent.En;
}

function titleIncidence(item){
  let local = DataStorage.getLocale();
  return local == 'es'? item.LabelIncidenceName.Es : item.LabelIncidenceName.En;
}

function titleWeigth(item){
  let weigthKg = (item.Weigth > 0) ? formatMilDecimal(item.Weigth.toFixed(2)) : 0;
  let weigthTon = (item.WeigthTon > 0) ? formatMilDecimal(item.WeigthTon.toFixed(2)) : 0;

  if(item.Weigth > 0 && item.WeigthTon > 0)
    return weigthTon+' TON / '+weigthKg+' KGS';
}

function ListTimeLine(){
  let listActivity = [];
  if(this.timeLineData.length !== 0){
    this.timeLineData.map(async (e) => {
      e.TimeLineJson.map(async (c) => {
        let currentDate = new Date();

        var dateStart = c.TransactionBegin ? 
                      DateFormater.formatDateTimeWithSlash(c.TransactionBegin)
                      : ''; 
        var dateEnd = c.TransactionFinish ? 
                      DateFormater.formatDateTimeWithSlash(c.TransactionFinish)
                      : ''; 
        var dateIncidence = c.TransactionDate ? 
                      DateFormater.formatDateTimeWithSlash(c.TransactionDate)
                      : ''; 

        var finishDate = this.circuitData.FinishDate ? this.circuitData.FinishDate : DateFormater.formatDateTimeWithSlash(currentDate);   
                   
        if(
            ((dateIncidence != '') && ((dateIncidence >= this.circuitData.TransactionDate ) && ( dateIncidence <= finishDate))) ||
            ((dateStart != '') && ((dateStart >= this.circuitData.TransactionDate ) && ( dateStart <= finishDate))) ||
            ((dateEnd != '') && ((dateEnd >= this.circuitData.TransactionDate ) && ( dateEnd <= finishDate)))
        ){  

          listActivity.push({
            IdX: c.IdX,
            dateBegin:  c.TransactionBegin ? 
                        DateFormater.formatDateTimeWithSlash(c.TransactionBegin)
                        : '',
            dateFinish: c.TransactionFinish ? 
                        DateFormater.formatDateTimeWithSlash(c.TransactionFinish)
                        : '',
            date: c.TransactionDate ?
                  DateFormater.formatDateTimeWithSlash(c.TransactionDate)
                  : '',
            LabelTimeLineEvent: { En: c.TimeLineEventEn, Es: c.TimeLineEventEs},
            LabelIncidenceName: { En: c.IncidenceNameEn, Es: c.IncidenceNameEs},
            IncidenceId: c.IncidenceId ? c.IncidenceId : '',
            TotalTime: c.TotalTime ? c.TotalTime : 0,
            Weigth: c.Weigth ? c.Weigth : 0,
            WeigthTon: c.WeigthTon ? c.WeigthTon : 0,
          })
        }
      })
    })
  }
  return listActivity;
}

async function getTimeLineList() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http.ejecutar('GET', 'VisitShipmentOrderTimeLine', { OrderId: this.order }, '').then(responseTimeLine => {
    if(responseTimeLine.status === 200){
      this.timeLineData = responseTimeLine.data.data;

      if(this.timeLineData.length > 0){
        this.DelayHrsDs = this.timeLineData[0].DelayHrsDs ? this.timeLineData[0].DelayHrsDs : '0';
        this.EffectiveHrsDs = this.timeLineData[0].EffectiveHrsDs ? this.timeLineData[0].EffectiveHrsDs : '0';
        this.TotalTimeHrsDs = this.timeLineData[0].TotalTimeHrsDs ? this.timeLineData[0].TotalTimeHrsDs : '0';
      }
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    }else{
      this.$store.state.visitas.apiStateForm = ENUM.ERROR;
    }
  }).catch(err => {
    this.$store.state.visitas.apiStateForm = ENUM.ERROR;
    this.$store.commit('visitas/messageMutation', err);
  });
}
//computed
  function ListActivityOriginal(){
    return this.ListTimeLine;  
  }
export default {
  name: 'index-time-line',
  data,
  mixins: [GeneralMixin],
  components: {
  },
  methods: {
    closeCollapse,
    titleTimeLineEvent,
    titleWeigth,
    titleIncidence,
    getTimeLineList,
  }, 
  props: {
    circuitData: null,
    containerData: {
      type: String,
    },
  }, 
  watch: {
    circuitData: function(newVal) {
      if(newVal){
        this.orderNumber = this.circuitData.OrderCode;
        this.order = this.circuitData.OrderId;

        if(this.Visit !== ''){
          this.getTimeLineList();
        }
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.refrescarTabla = false;
        this.getTimeLineList();
      }
    },
  },
  computed: {
    ListActivityOriginal,
    ListTimeLine,
    ...mapState({
        Visit: state => state.visitas.VisitId,
        tabIndex: state => state.visitas.tabIndex,
        dropItemOrder: state => state.visitas.dropItemOrder,
    })
  },
};
</script>
<style scope lang="scss">
.Time-Line-table {
  padding: 0.75rem;
  border: 1px solid;
  border-color: #d8dbe0;
}
table.Time-Line-table,
table.Time-Line-table td,
table.Time-Line-table th {
  border: 1px solid #d8dbe0;
}
@import 'css/line_time.scss';
</style>

import {
  required,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators';

const charNumbers = (value) => /^[a-zA-Z0-9 \u00f1\u00d1]+$/g.test(value);
const alphaNum = (value) => !helpers.req(value) || charNumbers(value);

const isValidFile = (value) => {
  if(!value)
    return false;
  
  if(value.type)
    return value.type.startsWith('image');
  else
      return (value != '');
};

const nameLength = (value) => {
  let Array = value.split(' ');
  for (let index = 0; index < Array.length; index++) {
    if(Array[index].length > 20){
      return false
    };
  }
  return true;
};

export default () => {
  return {
    imageIncidence: {
      RouteImage: { isValidFile },
      DocumentDescription: { alphaNum, maxLength: maxLength(50), nameLength },
    },
  }
}
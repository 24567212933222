<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      @update:show="refreshComponent()"
      :closeOnBackdrop="false"
      size="xl"
    >
        <CRow>
          <CCol sm="11" lg="6">
            <CRow class="justify-content-center">
              <CCol sm="11" lg="12">
                <CSelect
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                  :label="$t('label.Gamela')"
                  addLabelClasses="required text-right"
                  v-model.trim="order.implementId"
                  :value.sync="order.implementId"
                  :is-valid="hasError($v.order.implementId)" 
                  :invalid-feedback="errorMessage($v.order.implementId)"
                  :options="implementOptions"
                  size="sm"
                  @blur="$v.order.implementId.$touch()"
                  @change="setImplement"
                  :disabled="isEdit||NotEditable"
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CInput
                  :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                  :label="$t('label.transport')"
                  v-model.trim="$v.order.transportName.$model"
                  :value.sync="order.transportName"
                  :is-valid="hasError($v.order.transportName)" 
                  :invalid-feedback="errorMessage($v.order.transportName)"
                  size="sm"
                  @blur="$v.order.transportName.$touch()"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CInput 
                  :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                  :label="$t('label.driver')"
                  v-model.trim="$v.order.driverName.$model"
                  :is-valid="hasError($v.order.driverName)"
                  :invalid-feedback="errorMessage($v.order.driverName)"
                  size="sm"
                  @blur="$v.order.driverName.$touch()"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CInput 
                  :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                  :label="$t('label.vehicle')"
                  v-model.trim="$v.order.vehicleName.$model"
                  :is-valid="hasError($v.order.vehicleName)"
                  :invalid-feedback="errorMessage($v.order.vehicleName)"
                  size="sm"
                  @blur="$v.order.vehicleName.$touch()"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CTextarea
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  :label="$t('label.observation')"
                  addLabelClasses="text-right"
                  v-uppercase
                  v-model.trim="order.observation"
                  :value.sync="order.observation"
                  :is-valid="hasError($v.order.observation)" 
                  :invalid-feedback="errorMessage($v.order.observation)"
                  @blur="$v.order.observation.$touch()"
                  size="sm"
                  :disabled="isAnulled||NotEditable"
                  maxlength="150"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="11" lg="6">
            <CRow class="justify-content-center">
              <CCol sm="11" lg="12">
                <CSelect
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  label="BL BOOKING"
                  addLabelClasses="required text-right"
                  v-model.trim="order.blId"
                  :value.sync="order.blId"
                  :is-valid="hasError($v.order.blId)" 
                  :invalid-feedback="errorMessage($v.order.blId)"
                  :options="blOptions"
                  size="sm"
                  @blur="$v.order.blId.$touch()"
                  :disabled="isAnulled||NotEditable"
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CInput 
                  :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  :label="$t('label.yard')"
                  v-model.trim="$v.order.yardName.$model"
                  :is-valid="hasError($v.order.yardName)"
                  :invalid-feedback="errorMessage($v.order.yardName)"
                  size="sm"
                  @blur="$v.order.yardName.$touch()"
                  disabled
                />
              </CCol>
              <CCol sm="11" lg="12">
                <CSelect
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  :label="$t('label.checker')"
                  addLabelClasses="text-right"
                  v-model.trim="order.checkerId"
                  :value.sync="order.checkerId"
                  :is-valid="hasError($v.order.checkerId)" 
                  :invalid-feedback="errorMessage($v.order.checkerId)"
                  :options="checkerOptions"
                  size="sm"
                  @blur="$v.order.checkerId.$touch()"
                  :disabled="isAnulled||NotEditable"
                />
              </CCol>
              <CCol sm="11" lg="4" class="pr-0">
                <label class="col-form-label-sm required text-right col-sm-11 col-lg-12 pr-0" for="hordadesde">
                  {{$t('label.D_emission')}}
                </label>
              </CCol>
              <CCol sm="11" lg="4" class="pl-2">
                <div class="form-group form-row mb-0" rol="group">
                  <div class="col-sm-11 col-lg-12 input-group">
                    <vue-datepicker
                      valueType="format"
                      format="DD/MM/YYYY"
                      :open="openDate"
                      :lang="this.$i18n.locale"
                      @update:open="functionDate($event)"
                      @clickoutside="openDate = false"
                      @select="openDate = false"
                      @pick="openDate = false"
                      @close="openDate = false"
                      :clearable="false"
                      :disabled-date="validateDateRange"
                      v-model.trim="$v.order.date.$model"
                      :append-to-body="false"
                      class="w-100"
                      :disabled="isAnulled||NotEditable"
                    >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          addInputClasses="pr-0"
                          class="w-100 float-left"
                          v-uppercase
                          placeholder="DD/MM/YYYY"
                          :invalid-feedback="$t('label.required')"
                          :is-valid="hasError($v.order.date)"
                          v-model.trim="$v.order.date.$model"
                          @keypress="rightDate($event)"
                          size="sm"
                          @blur="$v.order.date.$touch()"
                          :disabled="isAnulled||NotEditable"
                        >
                          <template #append-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                  </div>
                </div>
              </CCol>
              <CCol sm="11" lg="4"  class="pl-0">
                <div class="form-group form-row mb-0" rol="group">
                  <div class="col-sm-11 col-lg-9 px-0 input-group">
                    <vue-datepicker
                      valueType="format"
                      type="time"
                      :format="'HH:mm'"
                      :clearable="false"
                      v-model.trim="$v.order.hour.$model"
                      :append-to-body="false"
                      class="w-100"
                      :disabled="isAnulled||NotEditable"
                    >
                      <template #input>
                        <CInput
                          addLabelClasses="required text-right"
                          class="w-100 float-left"
                          v-uppercase
                          placeholder="HH:MM"
                          :invalid-feedback="$t('label.required')"
                          :is-valid="hasError($v.order.hour)"
                          v-model.trim="$v.order.hour.$model"
                          @keypress="formatHourMMHH($event)"
                          size="sm"
                          @blur="$v.order.hour.$touch()"
                          :disabled="isAnulled||NotEditable"
                        >
                          <template #append-content>
                            <CIcon name="cil-clock" />
                          </template>
                        </CInput>
                      </template>
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="12" v-if="isEdit">
                <CSelect
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  :label="$t('label.status')"
                  addLabelClasses="required text-right"
                  v-model.trim="order.status"
                  :value.sync="status"
                  :options="orderShipmentStatusFormatted"
                  size="sm"   
                  :class="statusColorSelect == 1 ?
                    'status-open' : ( statusColorSelect == 2 ? 'status-close' : statusColorSelect == 3 ?
                    'status-inactive' : 'status-EndOperation')"
                  :disabled="fgNull"
                />
              </CCol>
              <CCol sm="12" lg="12" v-if="isEdit && isAnulled">
                <CTextarea
                  :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                  :label="$t('label.motive')"
                  addLabelClasses="text-right required"
                  v-uppercase
                  v-model.trim="order.observationAnulation"
                  :value.sync="order.observationAnulation"
                  :is-valid="hasError($v.order.observationAnulation)" 
                  :invalid-feedback="errorMessage($v.order.observationAnulation)"
                  @blur="$v.order.observationAnulation.$touch()"
                  size="sm"
                  maxlength="150"
                  :disabled="fgNull"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>

      <template #footer>

        <CButton outline color="add" @click="changeStatus()" :disabled="isSubmit" v-if="!showButtonExit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="(showModal=false, refreshComponent())"  :disabled="isSubmit" v-if="!showButtonExit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>


        <CButton color="wipe" @click="(showModal=false, refreshComponent())"  :disabled="isSubmit" v-if="showButtonExit">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import { order } from '@/_validations/incidence/orderValidation';

function data() {
  return {
    order: {
      transportName: '',
      driverName: '',
      vehicleName: '',
      implementId: '',
      yardName: '',
      blId: '',
      checkerId: '',
      date: 'DD/MM/YYYY',
      hour: 'HH:MM',
      dateOrigin: 'DD/MM/YYYY',
      hourOrigin: 'HH:MM',
      observation: '',
      observationAnulation: '',
      status: '',
      statusOld: '',
    },

    previousDate: new Date(),
    laterDate: new Date(),

    openDate: false,
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    isEdit: false,
    verifyDriver: false,
    verifyVehicle: false,
    isAnulled: false,
    fgNull: false,
    showButtonExit: false,
    NotEditable: false,
    bls: [],
    orderShipmentStatusOptions: [],
    checkers: [],
    implements: [],

    orderCode: '',
    VisitId: '',
    status: '',
    statusColorSelect: 1,
    orderId: '',
    titleModal: '',

    //loadingImplement: false,
    //loadingBlList: false,
    //loadingChecker: false,
    //loadingStatus: false,
  };
}

  function closeModal() {
    this.showModal=false;
    this.refreshComponent();
    this.$emit('child-refresh', true);
    this.openDate = false;
  }

  function functionDate(event) {
    if (event) {
      this.openDate = event;
    }
  }

  function refreshComponent() {
    this.order.transportName = '';
    this.order.driverName = '';
    this.order.vehicleName = '';
    this.order.blId = '';
    this.order.implementId = '';
    this.order.checkerId = '';
    this.order.yardName = '';
    this.order.hour = '';
    this.order.date = '';
    this.order.hourOrigin = '';
    this.order.dateOrigin = '';
    this.order.observation = '';
    this.order.status = '';
    this.order.statusOld = '';
    this.openDate = false;
    this.isAnulled = false;
    this.fgNull = false;
    this.NotEditable = false;
    this.showButtonExit = false;
    this.verifyDriver = '';
    this.verifyVehicle = '';
    this.order.observationAnulation = '';
    this.orderShipmentStatusOptions = [];
    //this.loadingImplement = false;
    //this.loadingBlList = false;
    //this.loadingChecker = false;
    //this.loadingStatus = false;
    this.statusColorSelect = 1;
    this.orderId = '';
    this.orderCode = '';
    this.$v.order.$reset();
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.order.$touch();

      if (this.$v.order.$error||this.fgNull) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      let dateNow = new Date();
      let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
      let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));

      let startTime = (this.itinerarySelected.Ata==undefined) ?
        DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
        DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));
        
      let startDate = (this.itinerarySelected.Ata==undefined) ?
        DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)) :
        DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Ata));

      if((this.order.date === currentDate) && (this.order.hour > currentTime)){
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsCurrentDateTimePleaseCheck');
      }

      if((this.order.date === startDate) && (this.order.hour < startTime)){
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsStartDateTimePleaseCheck');
      }

      if(this.isEdit && this.order.status === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7'){
        let newDate = this.order.date.split("/");
        newDate = newDate[2]+newDate[1]+newDate[0];

        let originDate = this.order.dateOrigin.split("/");
        originDate = originDate[2]+originDate[1]+originDate[0];

        if (newDate  < originDate) {
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errosDatePleaseCheck');
        } else if (originDate==newDate) {
          let newHour = this.order.hour.split(":");
          newHour = newHour[0]+newHour[1];
          let originHour = this.order.hourOrigin.split(":");
          originHour = originHour[0]+originHour[1];

          if (newHour < originHour) {
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errosHourPleaseCheck');
          }
        }  
      }

      const ShipmentOrderJson = {
        StowagePlanningBillOfLadingId: this.order.blId,
        StowagePlanningImplementVehicleId: this.order.implementId,
        TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.order.date+' '+this.order.hour),
        UserDeviceId: this.order.checkerId,
        Observation: this.order.observation,
        // ObservationAnulation: this.order.observationAnulation,
      };
      
      if(this.isEdit){
        ShipmentOrderJson.OrderId = this.orderId;
        ShipmentOrderJson.OrderStatus = this.order.status;
        if(this.isAnulled) {
          ShipmentOrderJson.ObservationAnulation = this.order.observationAnulation;
        }
      };
      let method = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'VisitShipmentOrder-update':'VisitShipmentOrder-insert';

      await this.$http
        .ejecutar (method, ruta, ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.closeModal();
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡Exito!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  } 

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function getDateRange(){
    let currentDate = new Date();

    if(this.itinerarySelected.Ata!=undefined){ 
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined){
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined){  
        this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined){
        this.laterDate = currentDate;
    }
  }

  function changeStatus() {
   
    if(this.order.statusOld !== this.order.status){ 
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.orderCode}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }

  //lista de chequeador
  async function getCheckerList() {
    // this.loadingOverlay = true;
      //this.loadingChecker = true;
      return this.$http
        .get('VisitUserDevice-list?VisitId='+this.Visit)
          .then((response) => (this.checkers = response.data.data
          ))
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        }).then(() => {
         // this.loadingChecker = false;
        });
      // this.loadingOverlay = false; 
  }

 //implements
  function getImplementList() {
    // this.loadingOverlay = true;
    //this.loadingImplement = true;
    return this.$http.ejecutar(
    'GET', 
    'VisitImplementVehicleActive-list', 
    { VisitId: this.Visit, StowagePlanningImplementVehicleId: this.order.implementId }
  )
    //this.$http.get('VisitImplement-list?VisitId='+this.Visit)
        .then((response) => (this.implements = response.data.data))
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
            });
          }).then(() => {
            //this.loadingImplement = false;
          });
    // this.loadingOverlay = false; 
  }

  //bls
  function getBlList() {
    // this.loadingOverlay = true;
    //this.loadingBlList = true;
    return this.$http
      .get('VisitBl-list?VisitId='+this.Visit)
        .then((response) => (this.bls = response.data.data))
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
        });
    }).then(() => {
      //this.loadingBlList = false;
    });
    // this.loadingOverlay = false; 
  }

  function getStatusList() {
    //this.loadingStatus = true;
    this.$http.get("OrderShipmentStatus-list")
      .then(response => {
        let StatusOptions = response.data.data;
        if (this.isEdit&&this.NotEditable) {
          this.orderShipmentStatusOptions =  StatusOptions.filter(Status => Status.ProcessStatusId.toUpperCase()=='678E76AA-B6C1-ED11-A889-000D3A0DEDC7'||
            Status.ProcessStatusId.toUpperCase()=='BED237C2-60CA-EC11-A87C-000D3A0DEDC7');
        } else {
          this.orderShipmentStatusOptions =  StatusOptions.filter(Status => Status.ProcessStatusId.toUpperCase()!='678E76AA-B6C1-ED11-A889-000D3A0DEDC7')
        }
        const findStatusColor = this.orderShipmentStatusOptions.find( item => item.ProcessStatusId === this.order.status );
        this.statusColorSelect = findStatusColor.Value;
      }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        //this.loadingStatus = false;
    });
  }

  async function setImplement(){
    this.loadingOverlay = true; 
    const implementData = this.implements.find( item => item.StowagePlanningImplementVehicleId === this.order.implementId);

    if(implementData !== undefined){
      let DriverCi = implementData.DriverCi ? implementData.DriverCi : '';
      let DriverName = implementData.DriverName ? implementData.DriverName : '';
      this.order.transportName = implementData.CarrierClientName ? implementData.CarrierClientName : '';
      this.order.vehicleName = implementData.LicensePlate ? implementData.LicensePlate : '';
      this.order.yardName = implementData.YardName ? implementData.YardName : '';
      this.order.driverName = DriverCi+' '+DriverName;

      this.$v.order.transportName.$touch();
      this.$v.order.driverName.$touch();
      this.$v.order.vehicleName.$touch();
      this.$v.order.yardName.$touch();
    }

    this.loadingOverlay = false; 
  }
    //computed
  //status
  function orderShipmentStatusFormatted(){
    let _this = this.$i18n.locale;
    const chart = [];
    this.orderShipmentStatusOptions.map(function(e){
      chart.push({
        value: e.ProcessStatusId, 
        label: _this=='en' ? e.ValueDsEn : e.ValueDsEs,
      })
    })
    return chart;
  }

  //chequeador
  function checkerOptions(){
      const chart = [
          {
              value: "",
              label: this.$t('label.select'),
          },
      ];
      this.checkers.map((item) => {
          chart.push({
              label: item.FullName,
              value: item.TransaUserId,
          });   
      });
      return chart;    
  }

  //implements
  function implementOptions(){
      const chart = [
          {
              value: "",
              label: this.$t('label.select'),
          },
      ];
      this.implements.map((item) => {
          chart.push({
              label: item.ImplementAlias,
              value: item.StowagePlanningImplementVehicleId,
          });   
      });
      return chart;    
  }

  //bl
  function blOptions(){
      const chart = [
          {
              value: "",
              label: this.$t('label.select'),
          },
      ];
      this.bls.map((item) => {
          chart.push({
              label: item.NroBl,
              value: item.StowagePlanningBillOfLadingId,
          });   
      });
      return chart;    
  }

  function currentStatus() {
    return this.order.status;
  }

export default {
  name: 'modal-order',
  data,
  props: {
    modal: null,
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){ return order(this.itinerarySelected.ModalityId, this.isAnulled, this.fgNull) },

  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true;    
        this.getDateRange(); 
        
        if (this.modal==true) {
          this.loadingOverlay = true;
          this.isEdit  = false;
          await this.getImplementList();
          await this.getBlList();
          await this.getCheckerList();
          this.titleModal = this.$t('label.nueva')+' '+this.$t('label.order');

          let currentDate = new Date();
          currentDate = DateFormater.formatTimeZoneToDateTime(currentDate);
          this.order.date = DateFormater.formatDateTimeWithSlashToOnlyDate(currentDate);
          this.order.hour = DateFormater.formatDateTimeWithSlashToOnlyTime(currentDate);
          this.loadingOverlay = false;
        } else {
          this.loadingOverlay = true;
          this.isEdit  = true;
          await this.getBlList();
          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.order')+': '+this.modal.OrderCode;
          this.orderCode = this.modal.OrderCode ? this.modal.OrderCode : '';
        
          this.order.implementId = this.modal.StowagePlanningImplementVehicleId ? this.modal.StowagePlanningImplementVehicleId : '';
          this.order.blId = this.modal.StowagePlanningBillOfLadingId ? this.modal.StowagePlanningBillOfLadingId : '';
          this.order.transportName = this.modal.ClientName ? this.modal.ClientName : '';
          this.order.yardName = this.modal.YardName ? this.modal.DriverCi+' '+this.modal.YardName : '';
          this.order.driverName = this.modal.DriverName ? this.modal.DriverCi+' '+this.modal.DriverName : '';
          this.order.checkerId = this.modal.TransaUserId ? this.modal.TransaUserId : '';
          this.order.vehicleName  = this.modal.LicensePlate ? this.modal.LicensePlate : '';
          this.order.hour = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionDate) : '';
          this.order.date = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionDate) : '';

          this.order.hourOrigin = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionDate) : '';
          this.order.dateOrigin = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionDate) : '';

          this.order.observation = this.modal.Observation ? this.modal.Observation : '';
          this.order.observationAnulation = this.modal.ObservationAnulation ? this.modal.ObservationAnulation : '';
          this.order.status = this.modal.OrderStatus ? this.modal.OrderStatus : '';
          this.order.statusOld = this.modal.OrderStatus ? this.modal.OrderStatus : '';
          this.orderId = this.modal.OrderId ? this.modal.OrderId : '';
          this.isAnulled = (this.modal.OrderStatus === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7') ? true : false;
          this.showButtonExit = (this.modal.OrderStatus.toUpperCase() === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7'||
            this.modal.OrderStatus.toUpperCase() === '678E76AA-B6C1-ED11-A889-000D3A0DEDC7') ? true : false;
          this.fgNull = this.isAnulled;
          this.NotEditable = this.modal.OrderStatus.toUpperCase()=='678E76AA-B6C1-ED11-A889-000D3A0DEDC7' ? true : false;
          await this.getStatusList();
          await this.getImplementList();
          await this.getCheckerList(); 
          this.setImplement();

          this.$v.$touch();
          this.loadingOverlay = false;
        }
        this.$emit('closeModal');
      }
    },
    currentStatus: function() {
      const findStatusColor = this.orderShipmentStatusOptions.find( item => item.ProcessStatusId === this.order.status );
      if(findStatusColor !== undefined){
        this.statusColorSelect=findStatusColor.Value;
        this.isAnulled = (this.order.status === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7') ? true : false;

        this.showButtonExit = (this.order.status==='BED237C2-60CA-EC11-A87C-000D3A0DEDC7'&&this.order.statusOld === 'BED237C2-60CA-EC11-A87C-000D3A0DEDC7')
          ||(this.order.status==='678E76AA-B6C1-ED11-A889-000D3A0DEDC7'&&this.order.statusOld==='678E76AA-B6C1-ED11-A889-000D3A0DEDC7') ? true : false;
      }
    },
  },
  methods: {
    closeModal,
    functionDate,
    getCheckerList,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    changeStatus,
    getImplementList,
    getBlList,
    getStatusList,
    setImplement,
  },
  computed: {
    implementOptions,
    blOptions,
    orderShipmentStatusFormatted,
    checkerOptions,
    currentStatus,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,         
    }),
  },
};
</script>
<style lang="scss">
.status-open {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #42aa91 !important;
  }

}
.status-close {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #FF8000 !important;
  }
  .form-control:focus {
    border-color: #FF8000 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #FF8000 !important;
    box-shadow: 0 0 0 0.1rem #FF8000 !important;
  }
}
.status-inactive {
   .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #e1373f !important;
  }
  .form-control:focus {
    border-color: #e1373f !important;
    -webkit-box-shadow: 0 0 0 0.1rem #e1373f !important;
    box-shadow: 0 0 0 0.1rem #e1373f !important;
  }
}
.status-EndOperation {
  .input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    border-color: #1A3760 !important;
  }
  .form-control:focus {
    border-color: #1A3760 !important;
    -webkit-box-shadow: 0 0 0 0.1rem #1A3760 !important;
    box-shadow: 0 0 0 0.1rem #1A3760 !important;
  }
}
</style>

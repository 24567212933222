import {
  required,
  minValue,
  maxLength,
  decimal
} from 'vuelidate/lib/validators';

const nameLength = (value) => {
  let Array = value.split(' ');
  for (let index = 0; index < Array.length; index++) {
    if(Array[index].length > 20){
      return false
    };
  }
  return true;
};

export const circuit = (FgApplyDate, FgApplyDateRange, FgCrane, FgHold, FgMachine, FgWeighingScale, FgYard) => {
  let ApplyDate = false;
  let ApplyDateRange = false;
  let Crane = false;
  let Hold = false;
  let Machine = false;
 // let WeighingScale = false;
  let Yard = false;
 // let Weight = '';
  if(FgApplyDate || FgApplyDateRange) { ApplyDate = { required } }
  if(FgApplyDateRange) { ApplyDateRange = { required } }

  if(FgCrane) { Crane = { required } }
  if(FgHold)  { Hold = { required } }
  if(FgMachine) { Machine = { required } }
  /*if(FgWeighingScale) { 
    WeighingScale = { required };
    Weight = { minValue:minValue(1) };
  }*/
  if(FgYard)  { Yard = { required } }

  return {
    circuit: {
      circuitId: { required },
      vesselHoldId: Hold, 
      yardId: Yard, 
      //truckWeghingScaleId: WeighingScale, 
      VisitCraneId: Crane,
      machineId: Machine,
      hourStart: ApplyDate,
      dateStart: ApplyDate,
      hourEnd: ApplyDateRange,
      dateEnd: ApplyDateRange,
      observation: { maxLength: maxLength(150), nameLength },
      //weigth: Weight,
      YardClientTpId: Yard,
    },
  }
}
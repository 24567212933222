var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('modalAddIncidenceImages',{attrs:{"edit":false,"modal":_vm.ShowModalAddImage},on:{"closeModal":function($event){_vm.ShowModalAddImage=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('modalListIncidenceImages',{attrs:{"anulled":_vm.isAnulled,"modal":_vm.ShowModalImageList},on:{"closeModal":function($event){_vm.ShowModalImageList=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('modalIncidence',{attrs:{"anulled":_vm.isAnulled,"modal":_vm.ShowModalIncidence,"order-id":_vm.$store.state.visitas.OrderId,"circuit-id":_vm.circuitId,"order-date":_vm.orderDate,"orderData":_vm.orderDataById[0]},on:{"closeModal":function($event){_vm.ShowModalIncidence=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center",attrs:{"sm":"8"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.return'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.return'),\n              placement: 'top',\n            }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":_vm.closeCollapse}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('label.incidences')+' - '+_vm.$t('label.noOrder')+': '+_vm.orderNumber))])],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"4"}},[_c('h6',{staticClass:"text-warning mr-3 mt-2"},[_c('CIcon',{staticClass:"mb-2 mr-1",attrs:{"name":"alertas","color":"wipe","size":"lg"}}),_vm._v(_vm._s(_vm.$t('label.incidenceOpen'))+"  ")],1),(!_vm.isAnulled)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.incidence'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.incidence'),\n              placement: 'top',\n            }"}],attrs:{"color":"add","size":"sm"},on:{"click":function($event){_vm.ShowModalIncidence=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nueva'))+" ")],1):_vm._e()],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"fields":_vm.fields,"items":_vm.itemsOrder,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingOverlay,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"alertGif",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.TransactionBegin != '' && item.TransactionFinish == '' && item.FgActOrderCircuit && item.Status != 'INACTIVO')?_c('div',{staticClass:"animation-item ml-2",staticStyle:{"display":"inline-block"}},[_c('h3',{staticClass:"text-danger"},[_c('CIcon',{staticStyle:{"width":"1.2rem","height":"1.2rem","font-size":"1.5rem"},attrs:{"name":"alertas","color":"danger"}})],1)]):_vm._e()])]}},{key:"Nro",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.Nro)+" ")])]}},{key:"Detalle",fn:function({ item }){return [(!item.FgEndOperation)?_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+_vm.$t('label.incidence'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.edit')+$t('label.incidence'),\n              placement: 'top-end',\n            }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"edit"},on:{"click":function($event){_vm.ShowModalIncidence=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(!_vm.isAnulled && item.Status == 'ACTIVO')?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.add')+_vm.$t('label.image'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.add')+$t('label.image'),\n              placement: 'top-end',\n            }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"add"},on:{"click":function($event){_vm.ShowModalAddImage=item}}},[_c('CIcon',{attrs:{"name":"photo"}})],1):_vm._e(),(item.DocumentJson.length > 0)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.see')+_vm.$t('label.image'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.see')+$t('label.image'),\n              placement: 'top-end',\n            }"}],attrs:{"size":"sm","color":"watch"},on:{"click":function($event){_vm.ShowModalImageList=item}}},[_c('CIcon',{attrs:{"name":"eye"}})],1):_vm._e()],1):_c('td')]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      class="modal-extended"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
    <div class="row">
      <CCol  lg="6">
        <CRow class="justify-content-center">
          <CCol  lg="12">
            <CSelect
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7 pr-0'}"
              :label="$t('label.incidenceType')"
              addLabelClasses="required text-right"
              v-model.trim="FormIncidence.incidence.IncidenceId"
              :value.sync="FormIncidence.incidence.IncidenceId"
              :is-valid="hasError($v.FormIncidence.incidence.IncidenceId)" 
              :invalid-feedback="errorMessage($v.FormIncidence.incidence.IncidenceId)"
              :options="incidenceTypeOptions"
              size="sm"
              @blur="$v.FormIncidence.incidence.IncidenceId.$touch()"
              :disabled="isAnulled"
              @change="verifyApplyOrder"
            />
          </CCol>
          <CCol  lg="12">
            <CSelect
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7 pr-0'}"
              :label="$t('label.checker')"
              addLabelClasses="text-right"
              v-model.trim="FormIncidence.incidence.checkerId"
              :value.sync="FormIncidence.incidence.checkerId"
              :is-valid="hasError($v.FormIncidence.incidence.checkerId)" 
              :invalid-feedback="errorMessage($v.FormIncidence.incidence.checkerId)"
              :options="checkerOptions"
              size="sm"
              @blur="$v.FormIncidence.incidence.checkerId.$touch()"
              :disabled="isAnulled"
            />
          </CCol>
          <CCol  lg="12" v-if="!FormIncidence.incidence.FgActOrderUpdate">
            <CTextarea
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7 pr-0'}"
              :label="$t('label.observation')"
              addLabelClasses="required text-right"
              v-uppercase
              v-model.trim="FormIncidence.incidence.observation"
              :value.sync="FormIncidence.incidence.observation"
              :is-valid="hasError($v.FormIncidence.incidence.observation)" 
              :invalid-feedback="errorMessage($v.FormIncidence.incidence.observation)"
              size="sm"
              @blur="$v.FormIncidence.incidence.observation.$touch()"
              :disabled="isAnulled"
              maxlength="150"
            />
          </CCol>
          <CCol sm="12" lg="12" v-if="isEdit">
            <CSelect
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7 pr-0'}"
              addLabelClasses="text-right required"
              v-model="FormIncidence.incidence.status"
              :value.sync="FormIncidence.incidence.status"
              :is-valid="statusSelectColor"
              :options="selectOptions"
              size="sm"
              :disabled="isAnulled"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol  lg="6">
        <CRow class="justify-content-center"> 
          <CCol  lg="3" class="pr-0">
            <label class="col-form-label-sm required text-right col-sm-12 col-lg-12 pr-0" for="hordadesde">
              {{$t('label.from')}}
            </label>
          </CCol>
          <CCol  lg="5" class="pl-2">
            <div class="form-group form-row mb-0" rol="group">
              <div class="col-sm-12 col-lg-12 input-group">
                <vue-datepicker
                  valueType="format"
                  format="DD/MM/YYYY"
                  :open="openDateStart"
                  :lang="this.$i18n.locale"
                  @update:open="functionDateStart($event)"
                  @clickoutside="openDateStart = false"
                  @select="openDateStart = false"
                  @pick="openDateStart = false"
                  @close="openDateStart = false"
                  :clearable="false"
                  :disabled-date="validateDateRange"
                  v-model.trim="$v.FormIncidence.incidence.dateStart.$model"
                  :append-to-body="false"
                  class="w-100"
                  @input="durationIncidence"
                  :disabled="isAnulled"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      addInputClasses="pr-0"
                      class="w-100 float-left"
                      v-uppercase
                      placeholder="DD/MM/YYYY"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.FormIncidence.incidence.dateStart)"
                      v-model.trim="$v.FormIncidence.incidence.dateStart.$model"
                      @keypress="rightDate($event)"
                      size="sm"
                      @blur="$v.FormIncidence.incidence.dateStart.$touch()"
                      @input="durationIncidence"
                      :disabled="isAnulled"
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CCol  lg="4"  class="pl-0">
            <div class="form-group form-row mb-0" rol="group">
              <div class="col-sm-12 col-lg-12 input-group">
                <vue-datepicker
                  valueType="format"
                  type="time"
                  :format="'HH:mm'"
                  :clearable="false"
                  v-model.trim="$v.FormIncidence.incidence.hourStart.$model"
                  :append-to-body="false"
                  class="w-100"
                  @input="durationIncidence"
                  :disabled="isAnulled"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      class="w-100 float-left"
                      v-uppercase
                      placeholder="HH:MM"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.FormIncidence.incidence.hourStart)"
                      v-model.trim="$v.FormIncidence.incidence.hourStart.$model"
                      @keypress="formatHourMMHH($event)"
                      size="sm"
                      @blur="$v.FormIncidence.incidence.hourStart.$touch()"
                      @input="durationIncidence"
                      :disabled="isAnulled"
                    >
                      <template #append-content>
                        <CIcon name="cil-clock" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CCol  lg="3" class="pr-0" v-if="!FormIncidence.incidence.FgActOrderUpdate">
            <label class="col-form-label-sm required text-right col-sm-12 col-lg-12 pr-0" for="hordadesde">
              {{$t('label.to')}}
            </label>
          </CCol>
          <CCol  lg="5" class="pl-2" v-if="!FormIncidence.incidence.FgActOrderUpdate">
            <div class="form-group form-row mb-0" rol="group">
              <div class="col-sm-12 col-lg-12 input-group">
                <vue-datepicker
                  valueType="format"
                  format="DD/MM/YYYY"
                  :open="openDateEnd"
                  :lang="this.$i18n.locale"
                  @update:open="functionDateEnd($event)"
                  @clickoutside="openDateEnd = false"
                  @select="openDateEnd = false"
                  @pick="openDateEnd = false"
                  @close="openDateEnd = false"
                  :clearable="false"
                  :disabled-date="validateDateRange"
                  v-model.trim="$v.FormIncidence.incidence.dateEnd.$model"
                  :append-to-body="false"
                  class="w-100"
                  @input="durationIncidence"
                  :disabled="isAnulled"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      addInputClasses="pr-0"
                      class="w-100 float-left"
                      v-uppercase
                      placeholder="DD/MM/YYYY"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.FormIncidence.incidence.dateEnd)"
                      v-model.trim="$v.FormIncidence.incidence.dateEnd.$model"
                      @keypress="rightDate($event)"
                      size="sm"
                      @blur="$v.FormIncidence.incidence.dateEnd.$touch()"
                      @input="durationIncidence"
                      :disabled="isAnulled"
                    >
                      <template #append-content>
                        <CIcon name="cil-calendar" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <CCol  lg="4"  class="pl-0" v-if="!FormIncidence.incidence.FgActOrderUpdate">
            <div class="form-group form-row mb-0" rol="group">
              <div class="col-sm-12 col-lg-12 input-group">
                <vue-datepicker
                  valueType="format"
                  type="time"
                  :format="'HH:mm'"
                  :clearable="false"
                  v-model.trim="$v.FormIncidence.incidence.hourEnd.$model"
                  :append-to-body="false"
                  class="w-100"
                  @input="durationIncidence"
                  :disabled="isAnulled"
                >
                  <template #input>
                    <CInput
                      addLabelClasses="required text-right"
                      class="w-100 float-left"
                      v-uppercase
                      placeholder="HH:MM"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.FormIncidence.incidence.hourEnd)"
                      v-model.trim="$v.FormIncidence.incidence.hourEnd.$model"
                      @keypress="formatHourMMHH($event)"
                      size="sm"
                      @blur="$v.FormIncidence.incidence.hourEnd.$touch()"
                      @input="durationIncidence"
                      :disabled="isAnulled"
                    >
                      <template #append-content>
                        <CIcon name="cil-clock" />
                      </template>
                    </CInput>
                  </template>
                  <template #icon-calendar>
                    <div style="display: none"></div>
                  </template>
                </vue-datepicker>
              </div>
            </div>
          </CCol>
          <label v-if="!FormIncidence.incidence.FgActOrderUpdate" class="col-form-label col-sm-12 col-lg-3 pr-0 text-right">
            {{$t('label.duration')}}
          </label>
          <div v-if="!FormIncidence.incidence.FgActOrderUpdate" class="col-sm-12 col-lg-9 text-center">
            <h3 class="label-duration" style="display: inline-block;">{{ FormIncidence.incidence.duration }}</h3>
          </div>
          <CCol  lg="12" v-if="FormIncidence.incidence.FgActOrderUpdate">
            <CTextarea
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
              :label="$t('label.observation')"
              addLabelClasses="required text-right"
              v-uppercase
              v-model.trim="FormIncidence.incidence.observation"
              :value.sync="FormIncidence.incidence.observation"
              :is-valid="hasError($v.FormIncidence.incidence.observation)" 
              :invalid-feedback="errorMessage($v.FormIncidence.incidence.observation)"
              size="sm"
              @blur="$v.FormIncidence.incidence.observation.$touch()"
              :disabled="isAnulled"
              maxlength="150"
            />
          </CCol>
          <!--CCol sm="12" lg="12" v-if="isEdit && FormIncidence.incidence.FgActOrderUpdate">
            <CSelect
              :label="$t('label.status')"
              :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
              addLabelClasses="text-right required"
              v-model="FormIncidence.incidence.status"
              :value.sync="FormIncidence.incidence.status"
              :is-valid="statusSelectColor"
              :options="selectOptions"
              size="sm"
              :disabled="isAnulled"
            />
          </CCol-->
        </CRow>
      </CCol>

      <CCol  lg="12" v-if="showCollapseAssociation">
        <CCard>
          <CCardHeader class="text-left bg-dark text-white">
            <b>{{ $t('label.edit')+' '+$t('label.orderAssociation')+': '+orderData.OrderCode }}</b>
          </CCardHeader>
          <CCardBody>
            <div class="row">
              <CCol lg="12">
                <CRow class="justify-content-center">
                  <CCol lg="3">
                    <label class="col-form-label col-sm-12 col-lg-8 text-right">{{$t('label.Gamela')}}</label>
                    <CSwitch
                      class="pt-2"
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="editGamela"
                    />
                  </CCol>
                  <CCol lg="3">
                    <label class="col-form-label col-sm-12 col-lg-8 text-right">{{$t('label.vehicle')}}</label>
                    <CSwitch
                      class="pt-2"
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="editVehicle"
                    />
                  </CCol>
                  <CCol lg="4">
                    <label class="col-form-label col-sm-12 col-lg-8 text-right">{{$t('label.Gamela')+' - '+$t('label.vehicle')}}</label>
                    <CSwitch
                      class="pt-2"
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="editGamelaVehicle"
                    />
                  </CCol>
                </CRow>
              </CCol>

              <CCol  lg="6">
                <CRow>
                  <CCol sm="12">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.Gamela')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.StowagePlanningImplementId"
                      :value.sync="FormIncidence.order.StowagePlanningImplementId"
                      :is-valid="hasError($v.FormIncidence.order.StowagePlanningImplementId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.StowagePlanningImplementId)"
                      :options="gamelaOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.StowagePlanningImplementId.$touch()"
                      :disabled="(!editVehicle && !editGamela && !editGamelaVehicle) || editVehicle"
                      @change="setDataAssociation"
                    />
                  </CCol>
<!-- -------------------- GAMELA - VEHICLE  START ------------------ -->
                  <CCol  lg="12" v-if="editGamelaVehicle">
                    <CInput
                      :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                      :label="$t('label.transport')"
                      v-model.trim="$v.FormIncidence.order.transportName.$model"
                      :value.sync="FormIncidence.order.transportName"
                      :is-valid="hasError($v.FormIncidence.order.transportName)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.transportName)"
                      size="sm"
                      @blur="$v.FormIncidence.order.transportName.$touch()"
                      disabled
                    />
                  </CCol>
                  <CCol  lg="12" v-if="editGamelaVehicle">
                    <CInput 
                      :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                      :label="$t('label.vehicle')"
                      v-model.trim="$v.FormIncidence.order.vehicleName.$model"
                      :is-valid="hasError($v.FormIncidence.order.vehicleName)"
                      :invalid-feedback="errorMessage($v.FormIncidence.order.vehicleName)"
                      size="sm"
                      @blur="$v.FormIncidence.order.vehicleName.$touch()"
                      disabled
                    />
                  </CCol>

                  
                 
<!-- -------------------- GAMELA - VEHICLE END ------------------ -->
<!-- -------------------------------------- -->
                  <CCol sm="12" v-if="!editGamelaVehicle">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.transport')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.StowagePlanningResourceId"
                      :value.sync="FormIncidence.order.StowagePlanningResourceId"
                      :is-valid="hasError($v.FormIncidence.order.StowagePlanningResourceId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.StowagePlanningResourceId)"
                      :options="transportOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.StowagePlanningResourceId.$touch()"
                      @change="getDriverVehicleList"
                      :disabled="!editVehicle"
                    />
                  </CCol>
                  <CCol sm="12" v-if="!editGamelaVehicle">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.vehicle')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.StowagePlanningVehicleId"
                      :value.sync="FormIncidence.order.StowagePlanningVehicleId"
                      :is-valid="hasError($v.FormIncidence.order.StowagePlanningVehicleId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.StowagePlanningVehicleId)"
                      :options="vehicleOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.StowagePlanningVehicleId.$touch()"
                      :disabled="FormIncidence.order.StowagePlanningResourceId == '' || !editVehicle"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol  lg="6">
                <CRow>
                  <CCol  lg="12" v-if="editGamelaVehicle">
                    <CInput 
                      :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7'}"
                      :label="$t('label.driver')"
                      v-model.trim="$v.FormIncidence.order.driverName.$model"
                      :is-valid="hasError($v.FormIncidence.order.driverName)"
                      :invalid-feedback="errorMessage($v.FormIncidence.order.driverName)"
                      size="sm"
                      @blur="$v.FormIncidence.order.driverName.$touch()"
                      disabled
                    />
                  </CCol>
                  <CCol sm="12" v-if="!editGamelaVehicle">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.driver')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.StowagePlanningDriverId"
                      :value.sync="FormIncidence.order.StowagePlanningDriverId"
                      :is-valid="hasError($v.FormIncidence.order.StowagePlanningDriverId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.StowagePlanningDriverId)"
                      :options="driverOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.StowagePlanningDriverId.$touch()"
                      :disabled="FormIncidence.order.StowagePlanningResourceId == '' || !editVehicle"
                    />
                  </CCol>
                  <!-- -------------------- GAMELA - VEHICLE START ------------------ -->
                  <CCol  lg="12" v-if="editGamelaVehicle">
                    <CInput 
                      :horizontal="{label:'text-right col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 pr-0'}"
                      :label="$t('label.yard')"
                      v-model.trim="$v.FormIncidence.order.yardName.$model"
                      :is-valid="hasError($v.FormIncidence.order.yardName)"
                      :invalid-feedback="errorMessage($v.FormIncidence.order.yardName)"
                      size="sm"
                      @blur="$v.FormIncidence.order.yardName.$touch()"
                      disabled
                    />
                  </CCol>
                  <!-- -------------------- GAMELA - VEHICLE END ------------------ -->
                  <CCol sm="12" v-if="!editGamelaVehicle">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.yard')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.YardId"
                      :value.sync="FormIncidence.order.YardId"
                      :is-valid="hasError($v.FormIncidence.order.YardId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.YardId)"
                      :options="yardOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.YardId.$touch()"
                      @change="getBlList"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CSelect
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                      :label="$t('label.clientYard')"
                      addLabelClasses="required text-right"
                      v-model.trim="FormIncidence.order.YardClientTpId"
                      :value.sync="FormIncidence.order.YardClientTpId"
                      :is-valid="hasError($v.FormIncidence.order.YardClientTpId)" 
                      :invalid-feedback="errorMessage($v.FormIncidence.order.YardClientTpId)"
                      :options="clientOptions"
                      size="sm"
                      @blur="$v.FormIncidence.order.YardClientTpId.$touch()"
                      :disabled=" FormIncidence.order.YardId == '' || editGamelaVehicle"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </div>


      <template #footer>
        <CButton v-if="!isAnulled" outline color="add" @click="changeStatus()"  :disabled="isSubmit">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton v-if="!isAnulled" color="wipe" @click="closeModal" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
        <CButton v-if="isAnulled" color="wipe" @click="closeModal" :disabled="isSubmit">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import { FormIncidence } from '@/_validations/incidence/incidenceValidation';
import moment from 'moment';

function data() {
  return {
    FormIncidence: {
      incidence: {
        IncidenceId: '',
        checkerId: '',
        duration: '00:00',
        dateStart: 'DD/MM/YYYY',
        hourStart: 'HH:MM',
        dateEnd: 'DD/MM/YYYY',
        hourEnd: 'HH:MM',
        observation: '',
        status: 1,
        FgActStatus: 1,
        FgActOrderUpdate: false,
      },
      order: {
        StowagePlanningImplementVehicleId: '',
        StowagePlanningResourceId: '',
        StowagePlanningDriverId: '',
        StowagePlanningVehicleId: '',
        StowagePlanningImplementId: '',
        YardId: '',
        YardClientTpId: '',
        blId: '',
        transportName: '',
        driverName: '',
        vehicleName: '',
        yardName: '',

      },
    },
    openDateStart: false,
    openDateEnd: false,
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    isEdit: false,
    isAnulled: false,
    showCollapseAssociation: false,
    editGamela: false,
    editVehicle: false,
    editGamelaVehicle: false,
    previousDate: new Date(),
    laterDate: new Date(),

    checkers: [],
    incidences: [],
    bls: [],
    yards: [],
    gamelas: [],
    transports: [],
    driverOptions: [],
    vehicleOptions: [],
    blOptions: [],
    clientOptions: [],

    VisitId: '',
    titleModal: '',
    orderCircuitId: '',
    incidenceName: '',
    orderId: '',
  };
}
  //lista tipos de daños
  async function getIncidenceTypeList() {
    this.loadingOverlay = true;
    
    let listado = Array;
    await this.$http.get("Incidence-list", { })
    .then(response => {
      listado = [...response.data.data];
      this.incidences =  listado.filter(incidence => !incidence.FgEndOperation)
      if(this.modal.IncidenceId !== ''){
        let dataIncidence = this.incidences.find(item => item.IncidenceId == this.FormIncidence.incidence.IncidenceId);
        
        if(dataIncidence != undefined && dataIncidence.FgActOrderUpdate){
          this.FormIncidence.incidence.FgActOrderUpdate = dataIncidence.FgActOrderUpdate;
          this.getYardList();

          if(this.orderData.StowagePlanningImplementVehicleId != ''){
            this.getTransportList();
          }
          
          this.showCollapseAssociation = dataIncidence.FgActOrderUpdate;

          this.FormIncidence.order.StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
          this.FormIncidence.order.StowagePlanningResourceId = this.orderData.StowagePlanningResourceId;
          this.FormIncidence.order.StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
          this.FormIncidence.order.StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
          this.FormIncidence.order.YardId = this.orderData.YardId;
          this.FormIncidence.order.YardClientTpId = this.orderData.YardClientTpId; 
          //this.FormIncidence.order.blId = this.orderData.StowagePlanningBillOfLadingId;

          if(this.orderData.YardId != ''){
            this.getBlList();
          }

          if(this.orderData.StowagePlanningDriverId != ''){
            this.getDriverVehicleList();
          }
          
        }else if(dataIncidence != undefined && !dataIncidence.FgActOrderUpdate){
          this.FormIncidence.incidence.FgActOrderUpdate = dataIncidence.FgActOrderUpdate;
          this.showCollapseAssociation = false;
        }
        this.loadingOverlay = false;
      }else{
        this.loadingOverlay = false;

      }
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      
    });
  }

  //lista de chequeador
  function getCheckerList() {
  
    this.loadingOverlay = true;
      this.$http
        .get('VisitUserDevice-list?VisitId='+this.Visit)
          .then((response) => (this.checkers = response.data.data
          
          ))
          .catch( err => {
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
        });
      this.loadingOverlay = false; 
  }

  //bls
  function getBlList() {
    this.loadingOverlay = true;
    const DataYard = this.yards.find(item => item.YardId == this.FormIncidence.order.YardId );

    if(DataYard != undefined){
      this.blOptions = [];
      let listadoYard = DataYard.BlYardDetailJson;
    
      for(let i=0; i < listadoYard.length; i++){
        this.blOptions.push({           
          value: listadoYard[i].BillOfLadingYardId, 
          label: listadoYard[i].NroBl,
        }); 
      }
    
      this.blOptions.unshift({
      value: "",
      label: this.$t('label.select')
    });

      this.clientOptions = [];
      let listadoClient = DataYard.BlYardDetailJson;
    
      for(let i=0; i < listadoClient.length; i++){
        this.clientOptions.push({           
          value: listadoClient[i].ClientTpId, 
          label: listadoClient[i].ClientName,
        }); 
      }
    
      this.clientOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
    }

    this.loadingOverlay = false; 
  }

  //yards
  function getYardList() {
    this.loadingOverlay = true;
    this.$http.ejecutar("GET", 'StowagePlanningMetalScrapYard-list', { CompanyBranchId: this.user.BranchJson[0].CompanyBranchId })
      .then((response) => (this.yards = response.data.data))
        .catch( err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
      });
    });
    this.loadingOverlay = false; 
  }
  //lista de gamelas con orden cerrada gamelas
  async function getGamelasListOrderInactive() {
    this.loadingOverlay = true;
    let listado = Array;
    
    await this.$http.ejecutar("GET", "VisitImplementVehicleActive-list-incidence", 
      { VisitId: this.Visit, StowagePlanningImplementVehicleId:this.orderData.StowagePlanningImplementVehicleId }
    )

    .then(response => {
      listado = [...response.data.data];
      this.gamelas = listado;
      const associationData = this.gamelas.find( item => item.StowagePlanningImplementId == this.FormIncidence.order.StowagePlanningImplementId );

      if( associationData != undefined){
        this.FormIncidence.order.transportName = associationData.CarrierAlias;
        this.FormIncidence.order.driverName = associationData.DriverName;
        this.FormIncidence.order.vehicleName = associationData.LicensePlate;
        this.FormIncidence.order.yardName = associationData.YardName;
      }
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });

  }

//lista de gamelas cuando la orden esta activa
  async function getGamelasListOrderActive() {
    this.loadingOverlay = true;
    let listado = Array;
    
    await this.$http.ejecutar("GET", "StowagePlanningImplementWithOutVehicle-list", 
      { StowagePlanningId: this.StowagePlanningId, StowagePlanningImplementVehicleId:this.orderData.StowagePlanningImplementVehicleId }
    ).then(response => {
      listado = [...response.data.data];
      this.gamelas = listado;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });

  }
    //transports
  async function getTransportList() {
    this.loadingOverlay = true;
    let listado = Array;

    await this.$http.ejecutar("GET", "StowagePlanningCarrierWithOutAssociated-list", 
      { StowagePlanningId: this.StowagePlanningId, StowagePlanningImplementVehicleId:this.orderData.StowagePlanningImplementVehicleId }
    )
    .then(response => {
      listado = [...response.data.data];
      this.transports = listado;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

  function getDriverVehicleList(){
    const TransportData = this.transportOptions.find( item => item.value == this.FormIncidence.order.StowagePlanningResourceId );

    if( TransportData != undefined ){
      this.driverOptions = [];
      let listadoDriver = TransportData.DriverJson;
      
      if( listadoDriver.length > 0 ){
        this.driverOptions = listadoDriver.map(listadoDriver => Object.assign({}, this.driverOptions, {
          value: listadoDriver.StowagePlanningDriverId,
          label: listadoDriver.DriverCi+' '+listadoDriver.DriverName,
        }));
      }
      
      this.driverOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });


      this.vehicleOptions = [];
      let listadoVehicle = TransportData.VehicleJson;
      
      if( listadoVehicle.length > 0 ){
        this.vehicleOptions = listadoVehicle.map(listadoVehicle => Object.assign({}, this.vehicleOptions, {
          value: listadoVehicle.StowagePlanningVehicleId, 
          label: listadoVehicle.LicensePlate,
        }));
      }
      
      this.vehicleOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
    }
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.FormIncidence.incidence.$touch();

      if (this.$v.FormIncidence.incidence.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      let dateNow = new Date();
      let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
      let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));

      let startTime = '';
      let startDate = '';
      if (this.orderData.TransactionDate!==undefined  && this.orderData.TransactionDate!==null){
          startTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.orderData.TransactionDate));
          startDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.orderData.TransactionDate));
      }

      let endTime = '';
      let endDate = '';
      if (this.orderData.FinishDate!==undefined  && this.orderData.FinishDate!==null){
        endTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatDateTimeWithSlash(this.orderData.FinishDate));
        endDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatDateTimeWithSlash(this.orderData.FinishDate));
      }

      /*******FECHA DESDE LLENAS*****/
      if( this.FormIncidence.incidence.dateStart !== '' ){
        //SI LA FECHA DESDE ES MENOR AL INICIO DE LA ORDEN
        if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isBefore(moment(startDate,'DD/MM/YYYY') ) ){ 
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromDateOrderBeginning');
        }
        //SI LA FECHA DESDE ES MAYOR A LA DEL CIERRE DE LA ORDEN
        if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isAfter(moment(endDate,'DD/MM/YYYY') ) ){
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromDateOrderFinish');
        }
        //SI LA FECHA DESDE ES MAYOR A LA FECHA ACTUAL
        if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isAfter(moment(currentDate,'DD/MM/YYYY') ) ){
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromCurrentDate');
        }
      }

      /*******FECHA HORAS DESDE LLENAS*****/
      if( this.FormIncidence.incidence.dateStart !== '' && this.FormIncidence.incidence.hourStart !== ''){
        //SI LA FECHA DESDE ES IGUAL AL INICIO DE LA ORDEN, Y LA HORA DESDE ES MENOR QUE LA DEL INICIO DE LA ORDEN
        if((moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isSame(moment(startDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourStart != '')){
          if( moment(this.FormIncidence.incidence.hourStart,'HH:mm').isSameOrBefore(moment(startTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromTimeOrderBeginning');
          }
        }
        //SI LA FECHA DESDE ES IGUAL A LA DEL CIERRE DE LA ORDEN, Y LA HORA DESDE ES MAYOR QUE LA DEL CIERRE DE LA ORDEN
        if((moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isSame(moment(endDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourStart != '')){
          if( moment(this.FormIncidence.incidence.hourStart,'HH:mm').isAfter(moment(endTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromTimeOrderFinish');
          }
        }
        //SI LA FECHA DESDE ES IGUAL A LA FECHA ACTUAL, Y LA HORA DESDE ES MAYOR QUE LA DE LA HORA ACTUAL
        if((moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isSame(moment(currentDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourStart != '')){
          if( moment(this.FormIncidence.incidence.hourStart,'HH:mm').isAfter(moment(currentTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromCurrentTime');
          }
        }
      }

      if(!this.FormIncidence.incidence.FgActOrderUpdate){
        /*******FECHA HASTA LLENAS*****/
        if( this.FormIncidence.incidence.dateEnd !== '' ){
          //SI LA FECHA HASTA ES MENOR AL INICIO DE LA ORDEN 
          if( moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isBefore(moment(startDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToDateOrderBeginning');
          }
          //SI LA FECHA HASTA ES MAYOR A LA DEL CIERRE DE LA ORDEN
          if( moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isAfter(moment(endDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToDateOrderFinish');
          }
          //SI LA FECHA HASTA ES MAYOR A LA FECHA ACTUAL
          if( moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isAfter(moment(currentDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToCurrentDate');
          }
        }

        /*******FECHA HORAS HASTA LLENAS*****/
        if( this.FormIncidence.incidence.dateEnd !== '' && this.FormIncidence.incidence.hourEnd !== ''){
          //SI LA FECHA DESDE ES IGUAL AL INICIO DE LA ORDEN, Y LA HORA DESDE ES MENOR QUE LA DEL INICIO DE LA ORDEN
          if((moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isSame(moment(startDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourEnd != '')){
            if( moment(this.FormIncidence.incidence.hourEnd,'HH:mm').isSameOrBefore(moment(startTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToTimeOrderBeginning');
            }
          }
          //SI LA FECHA DESDE ES IGUAL A LA DEL CIERRE DE LA ORDEN, Y LA HORA DESDE ES MAYOR QUE LA DEL CIERRE DE LA ORDEN
          if((moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isSame(moment(endDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourEnd != '')){
            if( moment(this.FormIncidence.incidence.hourEnd,'HH:mm').isAfter(moment(endTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToTimeOrderFinish');
            }
          }
          //SI LA FECHA DESDE ES IGUAL A LA FECHA ACTUAL, Y LA HORA DESDE ES MAYOR QUE LA DE LA HORA ACTUAL
          if((moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY').isSame(moment(currentDate,'DD/MM/YYYY'))) && (this.FormIncidence.incidence.hourEnd != '')){
            if( moment(this.FormIncidence.incidence.hourEnd,'HH:mm').isAfter(moment(currentTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToCurrentTime');
            }
          }
        }

        //FECHA DESDE IGUAL A FECHA HASTA
        if(this.FormIncidence.incidence.dateStart !== '' && this.FormIncidence.incidence.hourStart !== '' && this.FormIncidence.incidence.dateEnd !== '' && this.FormIncidence.incidence.hourEnd !== ''){
          //SI LA FECHA DESDE ES IGUAL A LA FECHA HASTA, Y LA HORA DESDE S MAYOR QUE LA HORA HASTA
          if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isSame(moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY'))){
            if( moment(this.FormIncidence.incidence.hourStart,'HH:mm').isSameOrAfter(moment(this.FormIncidence.incidence.hourEnd,'HH:mm')) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsFromTimeToTime');
            }
          }
        }

        //FECHA DESDE IGUAL A FECHA HASTA
        if ( this.FormIncidence.incidence.dateStart !== '' && this.FormIncidence.incidence.dateEnd !== ''){
          //SI LA FECHA DESDE ES MAYOR A LA FECHA HASTA
          if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isAfter(moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }

      if(this.showCollapseAssociation && !this.editGamela && !this.editVehicle && !this.editGamelaVehicle){
        this.submitIncidence();
      }else if(this.showCollapseAssociation && (this.editGamela || this.editVehicle || this.editGamelaVehicle)){
        let StowagePlanningImplementVehicleId = '';
        let StowagePlanningImplementVehicleIdNew = '';
        let StowagePlanningImplementId = '';
        let StowagePlanningVehicleId = '';
        let StowagePlanningDriverId = '';
        let YardId = '';
        let YardClientTpId = '';

        if( this.editGamela ){
          if(this.FormIncidence.order.StowagePlanningImplementId == this.orderData.StowagePlanningImplementId){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.requiredGamelaChange');
          }else{
            StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;
            StowagePlanningImplementId = this.FormIncidence.order.StowagePlanningImplementId;//gamela nueva 
            StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
            StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
            YardId = this.FormIncidence.order.YardId;
            YardClientTpId = this.FormIncidence.order.YardClientTpId;
          }
          
        } else if( this.editVehicle ){
          if(this.FormIncidence.order.StowagePlanningVehicleId == this.orderData.StowagePlanningVehicleId){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.requiredVehicleChange');
          }else{
            StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;
            StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
            StowagePlanningVehicleId = this.FormIncidence.order.StowagePlanningVehicleId;//vehiculo nuevo
            StowagePlanningDriverId = this.FormIncidence.order.StowagePlanningDriverId;//chofer nuevo
            YardId = this.FormIncidence.order.YardId;
            YardClientTpId = this.FormIncidence.order.YardClientTpId;
          }
        } else if( this.editGamelaVehicle ){
          if(this.FormIncidence.order.StowagePlanningImplementId == this.orderData.StowagePlanningImplementId){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.requiredGamelaChange');
          }else{
            StowagePlanningImplementVehicleIdNew = this.FormIncidence.order.StowagePlanningImplementVehicleId; //nueva asociacion
            StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;
            StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
            StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
            StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
            YardId = this.orderData.YardId;
            YardClientTpId = this.orderData.YardClientTpId;
          }
        }

        const VisitImplementVehicleOrderJson = {
          StowagePlanningImplementVehicleId: StowagePlanningImplementVehicleId,//Id asociacion Gamela - Vehiculo
          OrderId: this.$store.state.visitas.OrderId,//Id de la Orden a modificar
          StowagePlanningImplementId: StowagePlanningImplementId,//Id de la Nueva Gamela
          StowagePlanningVehicleId: StowagePlanningVehicleId,//Id del vehiculo asociado a la planificacion y de la asociacion 
          StowagePlanningDriverId: StowagePlanningDriverId,//Id del conductor de la asociacion 
          YardId: YardId,
          YardClientTpId: YardClientTpId,//Id del cliente en patio de la asociacion
          Observation: this.FormIncidence.incidence.observation,//Orbservacion aplicable para el cambio de gamela
          TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.FormIncidence.incidence.dateStart+' '+this.FormIncidence.incidence.hourStart),
          Status: 1,
        };

        if(this.editGamelaVehicle){ 
          VisitImplementVehicleOrderJson.StowagePlanningImplementVehicleIdNew = StowagePlanningImplementVehicleIdNew;
        }

        let method = 'PUT';
        let ruta = 'VisitImplementVehicleOrder-update';

        await this.$http
          .ejecutar (method, ruta, VisitImplementVehicleOrderJson, { root: 'VisitImplementVehicleOrderJson' })
          .then((response) => {
            if (response.status === (200 || 201)) {    
              this.closeModal();
              this.loadingOverlay = false;
              this.isSubmit = false;
              this.$notify({
                group: 'container',
                title: '¡Exito!',
                text: response.data.data[0].Response,
                type: "success"
              });
            }
          })
          .catch((e) => {
          this.loadingOverlay = false;
          this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: e,
              type: "error"
            });
          }).then(() => {
          this.loadingOverlay = false;
          this.isSubmit = false;
        }); 
      }else{
        this.submitIncidence();
      }
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  } 

  async function submitIncidence(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;    

      const OrderIncidenceJson = {
        OrderId: this.$store.state.visitas.OrderId,
        CircuitId: this.circuitId,
        IncidenceId: this.FormIncidence.incidence.IncidenceId,
        TransactionBegin: DateFormater.formatDateTimeWithoutSlash(this.FormIncidence.incidence.dateStart+' '+this.FormIncidence.incidence.hourStart),
        TransactionFinish: this.FormIncidence.incidence.FgActOrderUpdate ? '' : DateFormater.formatDateTimeWithoutSlash(this.FormIncidence.incidence.dateEnd+' '+this.FormIncidence.incidence.hourEnd),
        Observation: this.FormIncidence.incidence.observation,
        UserDeviceId: this.FormIncidence.incidence.checkerId,
      };
      
      if(this.isEdit){
        OrderIncidenceJson.OrderCircuitId = this.orderCircuitId;
        OrderIncidenceJson.Status = this.FormIncidence.incidence.status;
      };

      let method = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'VisitShipmentOrderIncidence-update':'VisitShipmentOrderIncidence-insert';

      await this.$http
        .ejecutar (method, ruta, OrderIncidenceJson, { root: 'OrderIncidenceJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.closeModal();
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡Exito!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      });
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  } 
  
  function closeModal() {
    this.refreshComponent();
    this.refreshCollapseOrderAssociation();
    this.$emit('child-refresh', true);
    
    this.showModal=false;
    this.openDateStart = false;
    this.openDateEnd = false;
  }

  function functionDateStart(event) {
    if (event) {
      this.openDateStart = event;
    }
  }

  function functionDateEnd(event) {
    if (event) {
      this.openDateEnd = event;
    }
  }

  function refreshComponent() {
    this.orderId = '';
    this.orderCircuitId = '';
    this.incidenceName = '';
    this.FormIncidence.incidence.IncidenceId = '';
    this.FormIncidence.incidence.checkerId = '';
    this.FormIncidence.incidence.duration = '00:00';
    this.FormIncidence.incidence.hourStart = '';
    this.FormIncidence.incidence.hourEnd = '';
    this.FormIncidence.incidence.dateStart = '';
    this.FormIncidence.incidence.dateEnd = '';
    this.FormIncidence.incidence.observation = '';
    this.FormIncidence.incidence.status = 1;
    this.FormIncidence.incidence.FgActStatus = 1;
    this.openDateStart = false;
    this.openDateEnd = false;
    this.isEdit = false;
    this.isAnulled = false;
    this.editGamela = false;
    this.editVehicle = false;
    this.editGamelaVehicle = false;
    this.showCollapseAssociation = false;
    this.FormIncidence.incidence.FgActOrderUpdate = false;
    this.editYard = false;
    this.$v.FormIncidence.incidence.$reset();
  }

  function refreshCollapseOrderAssociation() {
    this.FormIncidence.order.StowagePlanningImplementId = '';
    this.FormIncidence.order.StowagePlanningResourceId = '';
    this.FormIncidence.order.StowagePlanningVehicleId = '';
    this.FormIncidence.order.StowagePlanningDriverId = '';
    this.FormIncidence.order.YardId = '';
    this.FormIncidence.order.YardClientTpId = '';
    this.FormIncidence.order.blId = '';
    this.FormIncidence.order.transportName = '';
    this.FormIncidence.order.driverName = '';
    this.FormIncidence.order.vehicleName = '';
    this.FormIncidence.order.yardName = '';
    this.$v.FormIncidence.order.$reset();
  }

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function getDateRange(){
    let currentDate = new Date();

    if (this.orderData.TransactionDate!==undefined && this.orderData.TransactionDate!==null && this.orderData.TransactionDate!== ''){
      this.previousDate = DateFormater.formatDateRange(this.orderData.TransactionDate);
    }

    if (this.orderData.FinishDate!==undefined && this.orderData.FinishDate!==null && this.orderData.FinishDate!==''){
      this.laterDate = DateFormater.formatDateRange(this.orderData.FinishDate);
    } else {
      this.laterDate = currentDate;
    }
  }

  function statusSelectColor(){
    return this.FormIncidence.incidence.status === 1;
  }

  function changeStatus() {
    if(this.FormIncidence.incidence.FgActStatus !== this.FormIncidence.incidence.status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.incidenceName}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }
   
  async function verifyApplyOrder(){
    let dataIncidence = this.incidences.find(item => item.IncidenceId == this.FormIncidence.incidence.IncidenceId);

    if(dataIncidence != undefined && dataIncidence.FgActOrderUpdate){
      this.FormIncidence.incidence.FgActOrderUpdate = dataIncidence.FgActOrderUpdate;
      this.getYardList();

      if(this.orderData.StowagePlanningImplementVehicleId != ''){
        await this.getTransportList();
      }
      
      this.showCollapseAssociation = dataIncidence.FgActOrderUpdate;

      this.FormIncidence.order.StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
      this.FormIncidence.order.StowagePlanningResourceId = this.orderData.StowagePlanningResourceId;
      this.FormIncidence.order.StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
      this.FormIncidence.order.StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
      this.FormIncidence.order.YardId = this.orderData.YardId;
      this.FormIncidence.order.YardClientTpId = this.orderData.YardClientTpId; 
      //this.FormIncidence.order.blId = this.orderData.StowagePlanningBillOfLadingId;

      if(this.orderData.YardId != ''){
        await this.getBlList();
      }

      if(this.orderData.StowagePlanningDriverId != ''){
        await this.getDriverVehicleList();
      }
      
    }else if(dataIncidence != undefined && !dataIncidence.FgActOrderUpdate){
      this.FormIncidence.incidence.FgActOrderUpdate = dataIncidence.FgActOrderUpdate;
      this.showCollapseAssociation = false;
    }
  }

  function setDataAssociation(){
    if( this.editGamelaVehicle ){
      const associationData = this.gamelas.find( item => item.StowagePlanningImplementId == this.FormIncidence.order.StowagePlanningImplementId );
      if( associationData != undefined){
        this.FormIncidence.order.transportName = associationData.CarrierAlias;
        this.FormIncidence.order.driverName = associationData.DriverName;
        this.FormIncidence.order.vehicleName = associationData.LicensePlate;
        this.FormIncidence.order.yardName = associationData.YardName;
        this.FormIncidence.order.YardClientTpId = associationData.YardClientTpId;
        this.FormIncidence.order.StowagePlanningImplementVehicleId = associationData.StowagePlanningImplementVehicleId;
      }
    }
  }

  function durationIncidence(){
    if (this.FormIncidence.incidence.dateStart !== "" && this.FormIncidence.incidence.dateEnd !== "" && this.FormIncidence.incidence.hourStart !== "" && this.FormIncidence.incidence.hourEnd !== "") {
       //SI LA FECHA DESDE ES IGUAL A LA FECHA HASTA, Y LA HORA DESDE S MAYOR QUE LA HORA HASTA
       if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isSame(moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY'))){
        if( moment(this.FormIncidence.incidence.hourStart,'HH:mm').isSameOrAfter(moment(this.FormIncidence.incidence.hourEnd,'HH:mm')) ){
          this.FormIncidence.incidence.duration = '00:00';
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.errorsFromTimeToTime'),
            type: "error"
          });
        }else{
          this.FormIncidence.incidence.duration = DateFormater.datesDifferenceInHours(this.FormIncidence.incidence.dateStart, this.FormIncidence.incidence.dateEnd, this.FormIncidence.incidence.hourStart, this.FormIncidence.incidence.hourEnd);
        }
      }
      //SI LA FECHA DESDE ES MAYOR A LA FECHA HASTA
      else if( moment(this.FormIncidence.incidence.dateStart,'DD/MM/YYYY').isAfter(moment(this.FormIncidence.incidence.dateEnd,'DD/MM/YYYY') ) ){
        this.FormIncidence.incidence.duration = '00:00';
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.ErrorFromToPleaseCheck'),
            type: "error"
          });
      }
      else{   
        this.FormIncidence.incidence.duration = DateFormater.datesDifferenceInHours(this.FormIncidence.incidence.dateStart, this.FormIncidence.incidence.dateEnd, this.FormIncidence.incidence.hourStart, this.FormIncidence.incidence.hourEnd);
      }
    }  
  }

//computed
  function selectOptions(){
    return [
        { 
          value: 1, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: 0, 
          label: this.$t('label.INACTIVO')
        }
      ];
  }

  function checkerOptions(){
      const chart = [
          {
              value: "",
              label: this.$t('label.select'),
          },
      ];
      this.checkers.map((item) => {
          chart.push({
              label: item.FullName,
              value: item.UserDeviceId,
          });   
      });
      return chart;    
  }
    
  function incidenceTypeOptions(){
    let _this = this.$i18n.locale;

    const chart = [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
    this.incidences.map((item) => {
      chart.push({
        label: _this=='en' ? item.IncidenceNameEn : item.IncidenceNameEs,
        value: item.IncidenceId,
      });   
    });
    return chart;    
  }


  function gamelaOptions(){

    const chart = [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
    this.gamelas.map((item) => {
      chart.push({
        label: item.ImplementAlias,
        value: item.StowagePlanningImplementId,
      });   
    });
    return chart;    
  }

  function transportOptions(){

    const chart = [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
    this.transports.map((item) => {
      chart.push({
        label: item.Alias,
        value: item.StowagePlanningResourceId,
        DriverJson: item.DriverJson,
        VehicleJson: item.VehicleJson,
      });   
    });
    return chart;    
  }

  function yardOptions(){
    const chart = [
      {
        value: "",
        label: this.$t('label.select'),
      },
    ];
    this.yards.map((item) => {
      chart.push({
        label: item.YardName,
        value: item.YardId,
      });   
    });
    return chart;    
  }

export default {
  name: 'modal-incidence',
  data,
  props: {
    modal: null,
   // orderId: null,
    circuitId: null,
    orderDate: null,
    orderData: null,
    anulled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){ 
    return FormIncidence(this.FormIncidence.incidence.FgActOrderUpdate) 
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.orderId = this.$store.state.visitas.OrderId;
        this.showModal = true; 
        this.getDateRange(); 
        
        this.getCheckerList();
        if (this.modal==true) {
          this.refreshComponent();
          this.refreshCollapseOrderAssociation();
          this.isEdit  = false;
          await this.getIncidenceTypeList(); 
          this.titleModal = this.$t('label.nueva')+' '+this.$t('label.incidence');

          let currentDate = new Date();
          currentDate = DateFormater.formatTimeZoneToDateTime(currentDate);
          this.FormIncidence.incidence.dateStart = DateFormater.formatDateTimeWithSlashToOnlyDate(currentDate);
          this.FormIncidence.incidence.hourStart = DateFormater.formatDateTimeWithSlashToOnlyTime(currentDate);
        } else {  
          this.isAnulled =  this.anulled;
          this.isEdit  = true;
          await this.getIncidenceTypeList(); 

          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.incidence')+': '+this.modal.IncidenceName;

          this.FormIncidence.incidence.IncidenceId = this.modal.IncidenceId ? this.modal.IncidenceId : '';
          this.FormIncidence.incidence.checkerId = this.modal.TransaUserId ? this.modal.TransaUserId : '';
          
          this.FormIncidence.incidence.FgActOrderUpdate = this.modal.FgActOrderUpdate;

///////////////////
          if(this.modal.IncidenceId == '28C3A55F-AAB2-4CC5-8997-7282A872CA74'){
            this.FormIncidence.incidence.hourStart = this.modal.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransaRegDate) : '';
            this.FormIncidence.incidence.dateStart = this.modal.TransaRegDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransaRegDate) : '';
            this.FormIncidence.incidence.FgActOrderUpdate = true;
          }else{
            this.FormIncidence.incidence.hourStart = this.modal.TransactionBegin ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionBegin) : '';
            this.FormIncidence.incidence.dateStart = this.modal.TransactionBegin ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionBegin) : '';
            this.FormIncidence.incidence.FgActOrderUpdate = false;
          }
          this.FormIncidence.incidence.hourEnd = this.modal.TransactionFinish ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionFinish) : '';
          this.FormIncidence.incidence.dateEnd = this.modal.TransactionFinish ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionFinish) : '';

          let fecha = new Date();
          let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(fecha));
          let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(fecha));
          let totalTime = DateFormater.datesDifferenceInHours(this.FormIncidence.incidence.dateStart, currentDate,
                                                          this.FormIncidence.incidence.hourStart, currentTime);

          this.FormIncidence.incidence.duration = this.modal.TotalTime ? this.modal.TotalTime : totalTime;

          this.FormIncidence.incidence.observation = this.modal.Observation ? this.modal.Observation : '';
          this.FormIncidence.incidence.status = this.modal.FgActOrderCircuit ? 1 : 0;
          this.FormIncidence.incidence.FgActStatus = this.modal.FgActOrderCircuit ? 1 : 0;
          this.orderCircuitId = this.modal.OrderCircuitId ? this.modal.OrderCircuitId : '';
          this.incidenceName =  this.modal.IncidenceName ? this.modal.IncidenceName : '';

          this.$v.FormIncidence.$touch();
        }
        this.$emit('closeModal');
      }
    },
    showCollapseAssociation: async function(val){
      if(!val){
        this.editGamela = false;
        this.editVehicle = false;
        this.editGamelaVehicle = false;
        this.refreshCollapseOrderAssociation();
        
      }
    },
    editGamela: async function (val) {
      if(val){
        this.editVehicle = false;
        this.editGamelaVehicle = false;
        if(this.orderData.StowagePlanningImplementVehicleId != ''){
          await this.getGamelasListOrderActive();
        }
        this.FormIncidence.order.StowagePlanningResourceId = this.orderData.StowagePlanningResourceId;
        this.FormIncidence.order.StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
        this.FormIncidence.order.StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
        this.FormIncidence.order.StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
        this.FormIncidence.order.StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;

        this.$v.FormIncidence.order.$touch();
      }
    },
    editVehicle: async function (val) {
      if(val){
        this.editGamela = false;
        this.editGamelaVehicle = false;
        if(this.orderData.StowagePlanningImplementVehicleId != ''){
          await this.getGamelasListOrderActive();
        }
        this.FormIncidence.order.StowagePlanningResourceId = this.orderData.StowagePlanningResourceId;
        this.FormIncidence.order.StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
        this.FormIncidence.order.StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
        this.FormIncidence.order.StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
        this.FormIncidence.order.StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;

        this.$v.FormIncidence.order.$touch();
      }
    },
    editGamelaVehicle: async function (val) {
      if(val){
        this.editGamela = false;
        this.editVehicle = false;
        if(this.orderData.StowagePlanningImplementVehicleId != ''){
          await this.getGamelasListOrderInactive();
        }

        this.FormIncidence.order.StowagePlanningResourceId = this.orderData.StowagePlanningResourceId;
        this.FormIncidence.order.StowagePlanningVehicleId = this.orderData.StowagePlanningVehicleId;
        this.FormIncidence.order.StowagePlanningDriverId = this.orderData.StowagePlanningDriverId;
        this.FormIncidence.order.StowagePlanningImplementId = this.orderData.StowagePlanningImplementId;
        this.FormIncidence.order.StowagePlanningImplementVehicleId = this.orderData.StowagePlanningImplementVehicleId;

        this.$v.FormIncidence.order.$touch();
      }
    },
  },
  methods: {
    closeModal,
    functionDateStart,
    functionDateEnd,
    getIncidenceTypeList,
    getCheckerList,
    getBlList,
    getYardList,
    getGamelasListOrderInactive,
    getGamelasListOrderActive,
    getTransportList,
    refreshComponent,
    refreshCollapseOrderAssociation,
    submit,
    submitIncidence,
    validateDateRange,
    getDateRange,
    statusSelectColor,
    changeStatus,
    durationIncidence,
    verifyApplyOrder,
    getDriverVehicleList,
    setDataAssociation,
  },
  computed: {
    selectOptions,
    checkerOptions,
    incidenceTypeOptions,
    gamelaOptions,
    transportOptions,
    yardOptions,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,  
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,    
      StowagePlanningId: state => state.visitas.StowagePlanningId,
      OrderId: state => state.visitas.OrderId,
    }),
  },
};
</script>
<style lang="scss">
  .label-duration{
    color: rgb(19, 16, 16);
    font-size: 45px;
    margin-left: 0.5rem !important;
  }
</style>

<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />

    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CRow class="justify-content-center">
        <CCol sm="12" v-show="showModal">
          <div class="preview">    
            <picture-input
              v-if="showModal"
              ref="imageInput"
              width="400"
              height="400"
              accept="image/jpeg,image/png"
              size="40"
              :custom-strings="imgInputTexts"
              @change="handleFileUpload"
              :imgSrc="Route"
            >
            </picture-input>
          </div>
        </CCol>
        <CCol sm="12" v-show="!showModal && OrderCircuitId != ''">
          <div class="preview">    
            <picture-input
              v-if="!showModal && OrderCircuitId != ''"
              ref="imageInput"
              width="400"
              height="400"
              accept="image/jpeg,image/png"
              size="40"
              :custom-strings="imgInputTexts"
              @change="handleFileUpload"
              :imgSrc="Route"
            >
            </picture-input>
          </div>
        </CCol>
        <div class="col-sm-8" v-if="$v.imageIncidence.RouteImage.$error">
          <div class='text-invalid-feedback' v-if="!$v.imageIncidence.RouteImage.isValidFile">{{$t('label.badImage')}}</div>
        </div>
        <CCol sm="12" lg="10" class="mt-3">
          <CInput
            :horizontal="{label:'col-sm-11 col-lg-3', input:'col-sm-11 col-lg-8'}"
            :label="$t('label.description')"
            addLabelClasses="text-right"
            v-model.trim="imageIncidence.DocumentDescription"
            :value.sync="imageIncidence.DocumentDescription"
            :is-valid="hasError($v.imageIncidence.DocumentDescription)" 
            :invalid-feedback="errorMessage($v.imageIncidence.DocumentDescription)"
            size="sm"
            @blur="$v.imageIncidence.DocumentDescription.$touch()"
            maxlength="50"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          @click.stop="submitImage"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel')}}</span>
        </CButton>

      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import incidenceImageValidation from '@/_validations/incidence/incidenceImageValidation';
import PictureInput from '@/components/picture-input';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

function data() {
  let _lang = this.$i18n.locale;
  return {
    Route: _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg',
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,

    titleModal: '',
    OrderCircuitId: '',
    image: '',
    
    imageIncidence: {
      OrderCircuitId: '',
      RouteImage: '',
      DocumentDescription: '',

      OrderId: '',
      IncidenceId: '',
      CircuitId: '',
      TransactionBegin: '',
      TransactionFinish: '',
      Observation: '',
      UserDeviceId: '',
      Status: '',
      DocumentJson: [],
    },
  };
}

function closeModal() {
  this.$emit('child-refresh', true);
  this.refreshComponent();
  this.showModal = false;
}

function handleFileUpload(image) {
  this.imageIncidence.RouteImage = this.$refs.imageInput.file;
  this.image = image
}

function submitImage() {
  try {
    this.loadingOverlay = true;
    this.isSubmit = true;
    this.$v.imageIncidence.$touch();

    if (this.$v.imageIncidence.$error) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let formData = new FormData();
    formData.append('file1', this.imageIncidence.RouteImage);
    this.$http.file('VisitIncidence-file', this.imageIncidence.RouteImage)
    .then((response) => {
      if (response.status === (200 || 201)) {
        this.imageIncidence.RouteImage = response.data.data.files[0].path;
        this.imageIncidence.DocumentJson.push({
          OrderDocumentId: '',
          DocumentRoute: this.imageIncidence.RouteImage,
          DocumentDescription: this.imageIncidence.DocumentDescription,
          Status:1
        });
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.submit();
      }
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }); 
  } catch (e) {
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }
}

function submit() {
    this.loadingOverlay = true;
    this.isSubmit = true;

    let OrderIncidenceJson = {
        OrderCircuitId: this.imageIncidence.OrderCircuitId,
        OrderId: this.imageIncidence.OrderId,
        IncidenceId: this.imageIncidence.IncidenceId,
        CircuitId: this.imageIncidence.CircuitId,
        TransactionBegin: this.imageIncidence.TransactionBegin,
        TransactionFinish: this.imageIncidence.TransactionFinish,
        Observation: this.imageIncidence.Observation,
        UserDeviceId: this.imageIncidence.UserDeviceId,
        Status: this.imageIncidence.Status,
        DocumentJson: this.imageIncidence.DocumentJson,
    };

    this.$http.put('VisitShipmentOrderIncidence-update', OrderIncidenceJson, { root: 'OrderIncidenceJson' })
    .then(response => {
      if (response.status === (200 || 201)) {
        this.closeModal();
        this.loadingOverlay = false;
        this.isSubmit = false;
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: response.data.data[0].Response,
          type: "success"
        });
      }

    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.loadingOverlay = false;
      this.isSubmit = false;
    }).then(() => {
      this.loadingOverlay = false;
      this.isSubmit = false;
    });


}
function refreshComponent() {
  this.imageIncidence.OrderCircuitId = '';
  this.imageIncidence.RouteImage = '';
  this.imageIncidence.DocumentDescription = '';
  this.imageIncidence.OrderId = '';
  this.imageIncidence.IncidenceId = '';
  this.imageIncidence.CircuitId = '';
  this.imageIncidence.TransactionBegin = '';
  this.imageIncidence.TransactionFinish = '';
  this.imageIncidence.Observation = '';
  this.imageIncidence.UserDeviceId = '';
  this.imageIncidence.Status = '';
  this.imageIncidence.DocumentJson = [];

  this.image = "";
  this.titleModal= '';
  this.loadingOverlay= false;
  this.isSubmit = false;
  
  this.OrderCircuitId = '';
  if (this.$refs.imageInput) {
    this.$refs.imageInput.removeImage();
    this.$refs.imageInput.value = null;
    this.$refs.imageInput.file = null;
  }
  this.$v.imageIncidence.$reset(); 
}

function getIncidenceById(OrderCircuitId){
  this.loadingOverlay = true;
  let incidenceData = Array;
  this.$http.get("VisitShipmentOrderIncidence-by-Id", { OrderCircuitId: OrderCircuitId })
    .then(response => {
      incidenceData = [...response.data.data];

      if(incidenceData.length > 0){
        this.imageIncidence.OrderCircuitId = incidenceData[0].OrderCircuitId;
        this.imageIncidence.OrderId = incidenceData[0].OrderId ? incidenceData[0].OrderId : ''; 
        this.imageIncidence.IncidenceId = incidenceData[0].IncidenceId ? incidenceData[0].IncidenceId : ''; 
        this.imageIncidence.CircuitId = incidenceData[0].CircuitId ? incidenceData[0].CircuitId : ''; 
        this.imageIncidence.TransactionBegin = incidenceData[0].TransactionBegin ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(incidenceData[0].TransactionBegin)) : '';  //2022-06-21 13:45 
        this.imageIncidence.TransactionFinish = incidenceData[0].TransactionFinish ? DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(incidenceData[0].TransactionFinish)) : ''; 
        this.imageIncidence.Observation = incidenceData[0].Observation ? incidenceData[0].Observation : ''; 
        this.imageIncidence.UserDeviceId = incidenceData[0].TransaUserId ? incidenceData[0].TransaUserId : '';
        this.imageIncidence.Status =  incidenceData[0].FgActOrderCircuit ? 1 : 0; 

        for(let i=0; i < incidenceData[0].DocumentJson.length; i++){
          this.imageIncidence.DocumentJson.push({
            OrderDocumentId: incidenceData[0].DocumentJson[i].OrderDocumentId,
            DocumentRoute: incidenceData[0].DocumentJson[i].Route,
            DocumentDescription: incidenceData[0].DocumentJson[i].DocumentDescription,       
          });
        }
      }
    }).catch( err => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    this.loadingOverlay = false;
}

export default {
  name: 'modal-list-incidence-images',
  components: {
    PictureInput,
  },
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  validations:incidenceImageValidation,
  watch: {
    modal: async function () {
      if (this.modal) {
        this.imageIncidence.OrderCircuitId = this.modal.OrderCircuitId; 
        this.titleModal = this.modal.IncidenceName;
        this.showModal = true; 
        this.OrderCircuitId = this.modal.OrderCircuitId;
        this.loadingOverlay = false;
        this.getIncidenceById(this.modal.OrderCircuitId);

        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    handleFileUpload,
    submit,
    submitImage,
    refreshComponent,
    getIncidenceById,
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      VisitId: state => state.visitas.VisitId,
      branch: state => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss" scope>

</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('modalCircuit',{attrs:{"anulled":_vm.isAnulled,"modal":_vm.ShowmodalCircuit,"order":_vm.order,"order-date":_vm.orderDate,"orderData":_vm.orderData,"circuitData":_vm.circuitData},on:{"closeModal":function($event){_vm.ShowmodalCircuit=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center",attrs:{"sm":"8"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.return'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.return'),\n              placement: 'top',\n            }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":_vm.closeCollapse}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('label.circuits')+' - '+_vm.$t('label.noOrder')+': '+_vm.orderNumber))])],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"4"}},[(!_vm.isAnulled)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.circuit'),
              placement: 'top',
            }),expression:"{\n              content: $t('label.circuit'),\n              placement: 'top',\n            }"}],attrs:{"color":"add","size":"sm"},on:{"click":function($event){_vm.ShowmodalCircuit=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nuevo'))+" ")],1):_vm._e()],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"fields":_vm.fields,"items":_vm.itemsCircuit,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.CircuitId !== 'C36C691E-60D0-44A1-BE92-8B4296E8A071')?_c('div',[(!item.FgWeighingScale)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.edit')+_vm.$t('label.circuit'),
                placement: 'top-end',
              }),expression:"{\n                content: $t('label.edit')+$t('label.circuit'),\n                placement: 'top-end',\n              }"}],attrs:{"size":"sm","color":"edit"},on:{"click":function($event){_vm.ShowmodalCircuit=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e()],1):_vm._e()])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }